import React, {Component, useState} from 'react';
import CountUp from 'react-countup'
import { useStaticQuery, graphql } from 'gatsby'
import VisibilitySensor from "react-visibility-sensor";



const FunfactItem = (props) => {
const [appear, setAppear] = useState(false)


    const visibleChangeHandler = (isVisible) => {
        if(isVisible){
            setAppear(true)
        }
    }

 
        return (
            <div className="col-6 col-md-3 text-center">
                <div className="counter-item">
                    <h2 className="counter-number">
                        <CountUp start={appear ? 0 : null} end={props.counterNumber}>
                            {({ countUpRef }) => (
                                <div>
                                    <span ref={countUpRef} />
                                    <VisibilitySensor
                                        onChange={(isVisible) => visibleChangeHandler(isVisible)}>
                                        <span className="sr-only">+</span>
                                    </VisibilitySensor>
                                </div>
                            )}
                        </CountUp>
                    </h2>

                    <h6 className="counter-txt">{props.counterText}</h6>
                </div>
            </div>
        );
    }


export default FunfactItem;
import React from 'react';
import FunfactItem from "./funfactItem";
import { useStaticQuery, graphql } from 'gatsby'
import funfactbg from '../../assets/img/fun-fact-bg.jpg'
import Funfacts from '../../data/Funfact/funfact'

const query = graphql`
query {
    data:   allContentfulInicioDatos {
        edges {
          node {
            ttulo
            numero
          }
        }
      }
}
`

function Funfact() {

    const data = useStaticQuery(query)
    console.log(data)
    const orderedData = data.data.edges.map(node => console.log(node.node))
    console.log(orderedData)
    return (
        <div className="fun-fact-area sm-top parallax" style={{marginTop:'0px'}}  >
            <div className="container">
                <div className="row mtn-40">
                    {
                        data.data.edges.map(node=>(
                            <FunfactItem key={node.node.numero} counterNumber={node.node.numero} counterText={node.node.ttulo} />
                        )).reverse()
                    }
                </div>
            </div>
        </div>
    );
}

export default Funfact;